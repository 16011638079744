import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"

// 🎨 UI
import { Heading, Box } from "@chakra-ui/react"
import IntroPageWrapper from "../../components/Contents/IntroPageWrapper"

// 🚀 Transition
import Transition from "../../components/Animation/Transition"
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import TransitionButton from "../../components/Buttons/TransitionButton"
import { moveCameraToLayer } from "../../components/Animation/CameraAnimation"

export default function Zukunftsbilder({ data, location }) {
  const sendActionToStore = useDispatch()
  const post = data.wpZukunftsbild

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state ? location.state.direction : "fade"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)

  useEffect(() => {
    function changeMapColor() {
      sendActionToStore({
        type: `change/map/groundColor`,
        payload: post.acf_zukunftsbild_intro.colorKartenFarbe,
      })
    }
    changeMapColor()
  }, [post.acf_zukunftsbild_intro.colorKartenFarbe, sendActionToStore])

  useEffect(() => {
    sendActionToStore({
      type: "change/locationsActive",
      payload: false,
    })
  }, [])

  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
  }, [TRANSITION_INFO, sendActionToStore])

  return (
    <>
      <SEO title="Home" />
      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <IntroPageWrapper>
          <Box
            p={["24px", "32px", "32px", "64px"]}
            w="100%"
            maxWidth="700px"
            borderRadius="30px"
          >
            {/* Intro Text */}
            <Heading
              as="h1"
              fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
              mb="32px"
            >
              {post.acf_zukunftsbild_intro.textVisionStatement}
            </Heading>

            {/* Buttons */}
            <TransitionButton
              text={`${post.acf_zukunftsbild_intro.groupGrayButton.textGrayButtonLabel} ->`}
              link={post.acf_zukunftsbild_intro.groupGrayButton.buttonLinkGray}
              targetPageInAnimation="right"
              onClick={() => {
                moveCurrentPageOut(sendActionToStore, "left")
              }}
              hasShadow
            />
            <TransitionButton
              primary
              text={`${post.acf_zukunftsbild_intro.groupOrangeButton.textOrangeButtonLabel} ↓`}
              link={
                post.acf_zukunftsbild_intro.groupOrangeButton.buttonLinkOrange
              }
              targetPageInAnimation="down"
              onClick={() => {
                moveCameraToLayer(sendActionToStore, "schwerpunkte")
                moveCurrentPageOut(sendActionToStore, "up")
              }}
              hasShadow
            />

            {/* Extra Buttons */}
            {post.acf_zukunftsbild_intro.visionButtonRepeater?.length > 0 &&
              post.acf_zukunftsbild_intro.visionButtonRepeater.map(l => (
                <TransitionButton
                  text={l.buttonLabel}
                  link={l.buttonLink}
                  direction="fade"
                  onClick={() => {
                    moveCurrentPageOut(sendActionToStore, "fade")
                  }}
                  hasShadow
                />
              ))}
          </Box>
        </IntroPageWrapper>
      </Transition>
    </>
  )
}
export const query = graphql`
  query ZukunftsbildCardQuery($lang: String!) {
    wpZukunftsbild(slug: { eq: "intro" }, locale: { locale: { eq: $lang } }) {
      acf_zukunftsbild_intro {
        colorKartenFarbe
        groupGrayButton {
          textGrayButtonLabel
          buttonLinkGray
        }
        groupOrangeButton {
          buttonLinkOrange
          textOrangeButtonLabel
        }
        textVisionStatement
        visionButtonRepeater {
          ... on WpZukunftsbild_AcfZukunftsbildIntro_VisionButtonRepeater_VisionButton {
            buttonLabel
            fieldGroupName
            buttonLink
          }
        }
      }
    }
  }
`
